// const API = 'https://luminartechnolab-clnt-backend.herokuapp.com/api';
// const API ='http://localhost:9000/api'
const API = process.env.REACT_APP_API_URL;

const axios = require('axios');
const headers = {
  'Access-Control-Allow-Origin': '*',
  'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS'
};



export const getGalleryNoPagination = () => { 
  return axios.get(`${API}/gallery/AllList`)
 .then((data) => {
   return data.data.data;
 })
 .catch((err) => {
   return err
 });
};



export const getAllTestimonials = () => { 
    return axios.get(`${API}/testimonial/list`)
   .then((data) => {
     return data.data.data;
   })
   .catch((err) => {
     return err
   });
};


///list without pagination

export const getTestimonials = () => { 
    return axios.get(`${API}/testimonial/allList`)
   .then((data) => {
     return data.data.data;
   })
   .catch((err) => {
     return err
   });
};


export const getPlacements = (id) =>{
  return axios.get(`${API}/course/${id}`).then((data)=>{
      return data.data
  }).catch((err)=>{
      return err.response.data
  })
}