import React from "react";
import { useState, useEffect } from "react";
import "./Home.css";

import Instafeed from "../../lib/instafeed/instafeed";
import { Link, useNavigate, useParams } from "react-router-dom";
import { motion } from "framer-motion";

// company logos
import dns from "../Assets/images/logo/Dns-01.png";
import fingent from "../Assets/images/logo/fingent.png";
import wipro from "../Assets/images/logo/Wipro.png";
import tcs from "../Assets/images/logo/tcs-color.png";
import infosys from "../Assets/images/logo/infosys.png";
import cognizant from "../Assets/images/logo/Cognizant-Logo-PNG2.png";
import panapps from "../Assets/images/logo/panapps.png";
import impelsys from "../Assets/images/logo/impelsys.jpeg";
import rapidvalue from "../Assets/images/logo/rapidvalue.png";
import nucore from "../Assets/images/logo/nucore.png";
import naico from "../Assets/images/logo/naico.png";
import jtsi from "../Assets/images/logo/jtsi.jpeg";
import tryzens from "../Assets/images/logo/tryzens.png";
import sementor from "../Assets/images/logo/sementor.png";
import testvox from "../Assets/images/logo/testvox.png";
import growthplug from "../Assets/images/logo/growthplug.png";
import grapelime from "../Assets/images/logo/grapelime.png";
import nuvento from "../Assets/images/logo/nuvento.png";
import vaisesika from "../Assets/images/logo/vaisesika.png";
import lanware from "../Assets/images/logo/lanware.jpeg";
import logicplum from "../Assets/images/logo/logicplum.png";
import techvantage from "../Assets/images/logo/techvantage.png";
import thomson from "../Assets/images/logo/thomson.png";
import hyundai from "../Assets/images/logo/hyundai.jpeg";
import gizemeon from "../Assets/images/logo/gizemeon.png";
import draup from "../Assets/images/logo/draup.png";
import enfin from "../Assets/images/logo/enfin.png";
import zeazonz from "../Assets/images/logo/zeazonz.png";
import logidots from "../Assets/images/logo/logidots.png";
import prudent from "../Assets/images/logo/prudent.png";
import beinex from "../Assets/images/logo/beinex.png";
import intpurple from "../Assets/images/logo/intpurple.png";
//import ognitio from '../Assets/images/logo/ognitio.png'
import NewAgeSys from "../Assets/images/logo/NewAgeSys.png";
import claysys from "../Assets/images/logo/claysys.png";

import elastic from "../Assets/images/Technology Stack/elastic.svg";
import github from "../Assets/images/Technology Stack/Group 5377.svg";
import jenkins from "../Assets/images/Technology Stack/Group 5335.svg";
import java from "../Assets/images/Technology Stack/Group 5471.svg";
import node from "../Assets/images/Technology Stack/Group 5463.svg";
import dotnet from "../Assets/images/Technology Stack/Group 5467.svg";
import react from "../Assets/images/Technology Stack/Group 5483.svg";
import vuejs from "../Assets/images/Technology Stack/vuejs.svg";
import angular from "../Assets/images/Technology Stack/Group 5487.svg";
import android from "../Assets/images/Technology Stack/Group 5514.svg";
import magneto from "../Assets/images/Technology Stack/Group 5504.svg";
import amazon from "../Assets/images/Technology Stack/Group 5535.svg";
import sql from "../Assets/images/Technology Stack/Group 5539.svg";
import mongodb from "../Assets/images/Technology Stack/Group 5568.svg";
import mysql from "../Assets/images/Technology Stack/MySQL_logo_PNG1.svg";
import ios from "../Assets/images/Technology Stack/Group 5453.svg";
import { AxiosInstance } from "axios";
import Carousel from "react-bootstrap/Carousel";

import ReactPlayer from "react-player";
import $ from "jquery";
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from "react-html-parser";
import whitelogo from "../Assets/images/logo/whitelogo.png";
import certificateLogo from "../Assets/images/home/nactet.png";
import logo2 from "../Assets/images/home/iso.png";
import regdetails from "../Assets/images/social-media-icons/Instagram.svg";
import headerImg from "../Assets/images/home/webinar-concept-illustration_114360-4884.png";
import headerImg2 from "../Assets/images/home/webinar-concept-illustration_114360-4884.png";
import headerImg3 from "../Assets/images/home/01.jpg";
import headerImg4 from "../Assets/images/home/02.jpg";
import headerImg5 from "../Assets/images/home/03.jpg";
import headerImg6 from "../Assets/images/home/04.jpg";
import Group117 from "../Assets/images/home/Group117@2x.png";
import profilePhoto1 from "../Assets/images/owjjujsf.png";
import profilePhoto2 from "../Assets/images/pgvqmbzd.png";
import profilePhoto3 from "../Assets/images/s5qoqxsa.png";
import profilePhoto from "../Assets/images/owjjujsf.png";
import python from "../Assets/images/hover-icons/python-svgrepo-com.svg";
import AI from "../Assets/images/hover-icons/brain-bg-white.svg";
import AI1 from "../Assets/images/hover-icons/brain-white-2.svg";
import MERN from "../Assets/images/hover-icons/shield-bg-white.svg";
import insta1 from "../Assets/images/social-media-icons/instagram.png";
import insta from "../Assets/images/social-media-icons/Instagram.svg";
import Group27 from "../Assets/images/Group27.svg";
import dottedImg from "../Assets/images/Component1.png";
import office from "../Assets/images/crad-icons/office-chair-svgrepo-com.svg";
import officechair from "../Assets/images/crad-icons/Certificate.svg";
import icon1 from "../Assets/images/crad-icons/presentation-teacher-svgrepo-com.svg";
import profileImg1 from "../Assets/images/home/Ellipse12(2).png";
import Rectangle40 from "../Assets/images/Rectangle40.png";
import Rectangle41 from "../Assets/images/Rectangle41.png";
import videoPlay from "../Assets/images/home/video-svgrepo-com(2).png";
import start from "../Assets/images/192120_CKJ8iNahS6LCg7Ee.png";
import Component21 from "../Assets/images/Component21.png";
import carousal1Img1 from "../Assets/images/Best-computers-for-students.png";
import carousal1Img2 from "../Assets/images/student-laptop-pixabay-1200x800.png";
import docker1 from "../Assets/images/docker-1.svg";
import python5 from "../Assets/images/python-5.svg";
import devops2 from "../Assets/images/devops-2.svg";
import jquery from "../Assets/images/jquery.svg";
import bootstrap from "../Assets/images/bootstrap-4.svg";
import kb from "../Assets/images/kubernets.svg";
import Group16 from "../Assets/images/job-oriented/Group16.svg";
import Group15 from "../Assets/images/job-oriented/Group15.svg";
import Img1 from "../Assets/images/hover-icons/python-svgrepo-com-2.svg";
import Img2 from "../Assets/images/hover-icons/python-full-white-svgrepo-com-1.svg";
import MaskGroup2 from "../Assets/images/job-oriented/MaskGroup2.svg";
import { getAllCourses } from "../../Services/Auth/Actions/CourseActions";
import {
  getHomeDetails,
  getBannerDetails,
  getInstaToken,
} from "../../Services/Auth/Actions/HomeActions";
import { getAllPlacements } from "../../Services/Auth/Actions/PlacementActions";
import { getTestimonials } from "../../Services/Auth/Actions/TestimonialActions";
import { Footer } from "../Footer/Footer";
import videoimage from "../Assets/images/placement/webinar-concept-illustration_114360-4884@2x.png";
import Slider from "react-slick";
import { css } from "@emotion/react";
import FadeLoader from "react-spinners/FadeLoader";
import Meta from "../Meta/Meta";
const axios = require("axios");

const override = css`
  border-color: #6d1d8e;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

let globalCurrentIndex = 0;
let totalCount = 0;
var intervalFunction = null;

function Home() {
  const navigate = useNavigate();
  const [course, setCourse] = useState([]);
  const [home, setHome] = useState([]);
  const [placement, setPlacement] = useState([]);
  const [banner, setBanner] = useState([]);
  const [bannerOne, setBannerOne] = useState([]);
  const [stringData, setStringData] = useState("");
  const [token, setToken] = useState("");
  const [isValidInstaFeed, setIsValidInstaFeed] = useState(true);
  let [loading, setLoading] = useState(true);
  let [color, setColor] = useState("#6d1d8e");
  const [settings, setSettings] = useState({});
  const [courseOne, setCourseOne] = useState({});
  const [placementDataOne, setPlacementDataOne] = useState({});
  const [placementDataTwo, setPlacementDataTwo] = useState({});
  const [placementDataThree, setPlacementDataThree] = useState({});
  const [currentIndex, setCurrentIndex] = useState(0);

  const [test, setTest] = useState([]);
  const [testimonial, setTestimonial] = useState({
    title: "name",
    fullName: "Basil Anto",
    designation: "Data Science Intern",
    testimony:
      "Attended this course. I am so happy with the overall training. the faculty were cordial and cooperative and willing to help with smallest issues. I am offering my gratefulness to the Director of Luminar Technolab Rahul sir for his great support",
    id: 0,
    image1: Rectangle40,
    image2: Rectangle41,
  });

  const loadPageWithIndex = (index) => {
    setMyTimer();
    setCurrentIndex(index);
  };

  useEffect(async () => {
    var settings = {
      centerMode: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 3000,
      arrows: false,
    };
    document.addEventListener("contextmenu", (e) => {
      e.preventDefault();
    });

    $(".navbar-toggler.navbar-light:not(.collapsed)").click();
    var settings = {
      centerMode: true,
      infinite: true,
      slidesToShow: 4,
      slidesToScroll: 4,
      autoplay: true,
      autoplaySpeed: 1000,
      arrows: false,
    };

    setSettings(settings);
    await getInstaToken().then((data) => {
      const instafeeds = () => {
        var feed = new Instafeed({
          accessToken: data,
        });
        feed.run();
      };
      instafeeds();

      setToken(data);
    });
    getHomeDetails().then((data) => {
      setHome(data);
      const videoId = new URL(data[0].videoUrl).pathname.split("/").pop();
      setStringData(`https://www.youtube.com/embed/${videoId}?rel=0`);
    });

    getBannerDetails().then((data) => {
      setBanner(data);
      setLoading(false);
      // instafeeds();
      setBannerOne(data[0]);
    });
  }, []);

  useEffect(() => {
    getTestimonials().then((data) => {
      let testimonialArray = [];
      for (let i = 0; i < data.length; i++) {
        let obj = {
          fullName: data[i].fullName,
          designation: data[i].designation,
          testimony: data[i].testimony,
          id: i,
          image1: data[i].image,
          image2: data.length - 1 === i ? data[0].image : data[i + 1].image,
        };
        testimonialArray = [...testimonialArray, obj];
      }
      setTest(testimonialArray);
      setTestimonial(testimonialArray[0]);
      totalCount = testimonialArray.length;
      setMyTimer();
    });
  }, []);

  useEffect(() => {
    getAllPlacements().then((data) => {
      setPlacement(data);
      setPlacementDataOne(data[0]);
      setPlacementDataTwo(data[1]);
      setPlacementDataThree(data[2]);
    });
    getAllCourses().then((data) => {
      console.log(data, "aaaaaaaaaaaaaaaaaaaaaaaaaaaaaa");
      setCourse(data);
      setCourseOne(data[data.length - 2]);
    });
  }, []);
  var mobilelogoscroll = {
    centerMode: true,
    infinite: true,
    slidesToShow: 2,
    slidesToScroll: 2,
    autoplay: true,
    autoplaySpeed: 1000,
    arrows: false,
  };
  const iterateFn = () => {
    if (globalCurrentIndex < totalCount - 1) {
      globalCurrentIndex = globalCurrentIndex + 1;
    } else {
      globalCurrentIndex = 0;
      setMyTimer();
    }
    setCurrentIndex(globalCurrentIndex);
  };

  const setMyTimer = () => {
    clearInterval(intervalFunction);
    intervalFunction = setInterval(iterateFn, 5000);
  };

  useEffect(() => {
    if (currentIndex < test.length) {
      setTestimonial(test[currentIndex]);
    }
  }, [currentIndex]);

  useEffect(() => {
    if (course.length == 0) {
      return;
    }
    window.jQuery(".job-oriented-card-container-small-device").slick({
      centerMode: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 3000,
      arrows: false,
    });
  }, [course]);
  // const instafeeds=()=>{
  //   var feed = new Instafeed({
  //     accessToken:token
  //    // "IGQVJYdkdFX25KcGNxd2ZAtT0M1ZAExpRlFJN24zajVtYXpQbnNfU2ZAMUlhPcW1KeHJyelg2OFlZAV2R4NUF6RkFQWV80ZAjNVSzdkMFphYmU3SlVmM3AxX3dwUGxfLXRLNjl5MHFlaml3",
  //   });
  //   feed.run();
  // }

  return (
    <>
      <Meta
        title=" Kerala's No.1 Software Training Institute in Kochi (Cochin) | Luminar Technolab "
        content="Luminar Technolab is a No.1 Software Training Institute in Kochi & Mavoor Road Calicut, offering you Job Oriented courses on Data Science, Data Analytics, Machine Learning, Python, Big Data & Other Certified IT programs"
      />

      <div className="large-device-banner">
        {loading ? (
          <div className="loadersection">
            <div class="loader"></div>
          </div>
        ) : (
          <header>
            <div class="container-fluid">
              <div class="row d-flex">
                <div
                  class="col col-sm-12 col-md-12 align-items-center
                justify-content-center p-0"
                >
                  <div
                    id="carousel-home"
                    class="carousel slide"
                    data-ride="carousel"
                    data-interval="2000"
                    data-pause="false"
                  >
                    <ol class="carousel-indicators">
                      {banner &&
                        banner.map((data, i) => {
                          return (
                            <>
                              <li
                                data-target="#carousel-home"
                                data-slide-to={i}
                                class={i == 0 ? "active" : ""}
                              ></li>
                            </>
                          );
                        })}
                    </ol>
                    <div class="carousel-inner">
                      {/* start */}
                      {banner &&
                        banner.map((data, i) => {
                          return (
                            <div
                              class={
                                i == 0
                                  ? "carousel-item active"
                                  : "carousel-item"
                              }
                            >
                              <div class="home-carousel-item-body">
                                <div class="container">
                                  <div class="row">
                                    <div class="col-12 col-md-6 home-carousel-component-info">
                                      {/* <img
                                  src={banner[i].bannerImg}                
                                   alt="landing-page-webinar-concept-illustration"
                                  class="home-carousel-header-image-small"
                                /> */}
                                      <div className="home-corousel-content">
                                        {ReactHtmlParser(
                                          banner[i].bannerContent
                                        )}
                                        {ReactHtmlParser(
                                          banner[i].bannerContentBottom
                                        )}
                                      </div>
                                      <div class="home-carousel-certificates">
                                        <img
                                          src={certificateLogo}
                                          alt="certificate-logo"
                                          class="img-fluid
                                                      home-carousel-one-certificate
                                                      image-one"
                                        />
                                        <div class="vertical-dash-wrapper">
                                          <p class="vertical-dash">|</p>
                                        </div>

                                        <img
                                          src={logo2}
                                          alt="certificate-logo"
                                          class="img-fluid home-carousel-one-certificate image-two"
                                        />
                                        <p class="vertical-dash">|</p>

                                        <img
                                          src="https://www.iao.org/assets/images/email/seal/iao-seal.png"
                                          alt="International Accreditation Organization - IAO"
                                          class="img-fluid home-carousel-one-certificate image-two"
                                          onClick={() =>
                                            window.open(
                                              "https://www.iao.org/India-Kerala/Luminar-Technolab",
                                              "_blank"
                                            )
                                          }
                                        />
                                      </div>
                                      <button
                                        class="view-course"
                                        onClick={() => {
                                          navigate("/courses");
                                        }}
                                      >
                                        View Courses
                                      </button>
                                    </div>
                                    <div
                                      class="col-12 col-md-6
                                              home-carousel-header-image-section"
                                    >
                                      <img
                                        src={banner[i].bannerImg}
                                        alt="landing-page-webinar-concept-illustration"
                                        class="home-carousel-header-image"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                </div>
              </div>
              {/* carousel end */}
              <div class="row home-registration-content-section">
                <div class="col-12 d-flex">
                  <img
                    src={Group117}
                    alt="registration
                    detials"
                    class="img-fluid
                    home-registration-contents"
                  />
                </div>
              </div>
            </div>
          </header>
        )}
      </div>
      {/* small device */}
      <div className="small-device-banner">
        {loading ? (
          <div className="loadersection">
            <div class="loader"></div>
          </div>
        ) : (
          <header>
            <div class="container-fluid">
              <div class="row d-flex">
                <div
                  class="col col-sm-12 col-md-12 align-items-center
                justify-content-center p-0"
                >
                  <div
                    id="carousel-home"
                    class="carousel slide"
                    data-ride="carousel"
                    data-interval="2000"
                  >
                    <ol class="carousel-indicators">
                      {banner &&
                        banner.map((data, i) => {
                          return (
                            <>
                              <li
                                data-target="#carousel-home"
                                data-slide-to={i}
                                class={i == 0 ? "active" : ""}
                              ></li>
                            </>
                          );
                        })}
                    </ol>
                    <div class="carousel-inner">
                      {/* start */}
                      {banner &&
                        banner.map((data, i) => {
                          return (
                            <div
                              class={
                                i == 0
                                  ? "carousel-item active"
                                  : "carousel-item"
                              }
                            >
                              <div class="home-carousel-item-body">
                                <div class="container">
                                  <div class="row">
                                    <div class="col-12 col-md-6 home-carousel-component-info">
                                      {/* <img
                                  src={banner[i].bannerImg}                
                                   alt="landing-page-webinar-concept-illustration"
                                  class="home-carousel-header-image-small"
                                /> */}

                                      <div className="home-corousel-content">
                                        {ReactHtmlParser(
                                          banner[i].bannerContent
                                        )}
                                        <div className="home-carousel-header-smalldevice-image">
                                          <img
                                            src={banner[i].bannerImg}
                                            alt="landing-page-webinar-concept-illustration"
                                            class="home-carousel-header-image"
                                          />
                                        </div>
                                        {ReactHtmlParser(
                                          banner[i].bannerContentBottom
                                        )}
                                      </div>
                                      <div class="home-carousel-certificates">
                                        <img
                                          src={certificateLogo}
                                          alt="certificate-logo"
                                          class="img-fluid
                                                      home-carousel-one-certificate
                                                      image-one"
                                        />
                                        <div class="vertical-dash-wrapper">
                                          <p class="vertical-dash">|</p>
                                        </div>

                                        <img
                                          src={logo2}
                                          alt="certificate-logo"
                                          class="img-fluid home-carousel-one-certificate image-two"
                                        />
                                        <p class="vertical-dash">|</p>
                                        <a
                                          href="https://www.iao.org/India-Kerala/Luminar-Technolab"
                                          target="_blank"
                                          rel="noreferrer"
                                        >
                                          <img
                                            src="https://www.iao.org/assets/images/email/seal/iao-seal.png"
                                            alt="International Accreditation Organization - IAO"
                                            class="img-fluid home-carousel-one-certificate image-two"
                                          />
                                        </a>
                                      </div>
                                      <button
                                        class="view-course"
                                        onClick={() => {
                                          navigate("/courses");
                                        }}
                                      >
                                        View Courses
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                </div>
              </div>
              {/* carousel end */}
              <div class="row home-registration-content-section">
                <div class="col-12 d-flex">
                  <img
                    src={Group117}
                    alt="registration
                    detials"
                    class="img-fluid
                    home-registration-contents"
                  />
                </div>
              </div>
            </div>
          </header>
        )}
      </div>
      <section class="home-mission-section">
        <div class="container">
          <div
            class="row r mx-0 home-our-mission-section-content
                small-square"
          >
            <div
              class="col-12 col-sm-12
                    col-md-3 column-margin p-0"
            >
              <div class="mission-image-overlay-parent">
                <img
                  // src={profilePhoto1}
                  src={placementDataOne.image}
                  class="img-fluid image-margin"
                  alt="profile-photo"
                />
                <div class="mission-image-overlay">
                  <p class="mission-image-name">
                    {placementDataOne.name}
                    <span class="margin-image-role">
                      {placementDataOne.designation}
                    </span>
                  </p>
                  <p class="mission-education-detials">
                    <span class="mission-education-heading">
                      Graduate in: <br />
                    </span>
                    {placementDataOne.professionalCourse}
                    <br />
                    {placementDataOne.college}
                  </p>
                  <p class="mission-placement">
                    <span class="mission-education-heading">
                      For placement in: <br />
                    </span>
                    {placementDataOne.company}
                  </p>
                </div>
              </div>
              <div></div>
            </div>

            <div
              class="col-12 col-sm-12
                    col-md-3 column-margin p-0"
            >
              <div class="mission-image-overlay-parent">
                <img
                  // src={profilePhoto2}
                  src={placementDataTwo.image}
                  class="img-fluid image-margin"
                  alt="profile-photo"
                />
                <div class="mission-image-overlay">
                  <p class="mission-image-name">
                    {placementDataTwo.name} <br />
                    <span class="margin-image-role">
                      {placementDataTwo.designation}{" "}
                    </span>
                  </p>
                  <p class="mission-education-detials">
                    <span class="mission-education-heading">
                      Graduate in: <br />
                    </span>
                    {placementDataTwo.professionalCourse} <br />
                    {placementDataTwo.college}
                  </p>

                  <p class="mission-placement">
                    <span class="mission-education-heading">
                      For placement in: <br />
                    </span>
                    {placementDataTwo.company}
                  </p>
                </div>
              </div>

              <div class="mission-image-overlay-parent">
                <img
                  // src={profilePhoto3}
                  src={placementDataThree.image}
                  class="img-fluid image-margin"
                  alt="profile-photo"
                />
                <div class="mission-image-overlay">
                  <p class="mission-image-name">
                    {placementDataThree.name} <br />
                    <span class="margin-image-role">
                      {placementDataThree.designation}
                    </span>
                  </p>

                  <p class="mission-education-detials">
                    <span class="mission-education-heading">
                      Graduate in: <br />
                    </span>
                    {placementDataThree.professionalCourse} <br />
                    {placementDataThree.college}
                  </p>

                  <p class="mission-placement">
                    <span class="mission-education-heading">
                      For placement in: <br />
                    </span>
                    {placementDataThree.company}
                  </p>
                </div>
              </div>
            </div>

            <div
              class="col-12 col-sm-12
                    col-md home-our-mission-section-text-section"
            >
              <p class="home-our-mission-section-para">Our Mission:</p>
              <p className="home-our-mission-section-highlight-para">
                Empowering Students for Career Success with Job-Ready Training
                and Placement Support
              </p>
              <p class="home-our-mission-right-text-normal-para">
                Our mission is to help every student who Successfully completes
                our specialised courses launch a successful career. Our
                comprehensive placement support includes resume preparation,
                mock interviews, aptitude test training, and interview coaching.
                We’re committed to providing unlimited placement assistance
                until each student secures a satisfying position.
              </p>
              <button
                class="small-white-button mb-2"
                onClick={() => {
                  navigate("/placements");
                }}
              >
                View All
              </button>
            </div>

            <div class="square-one image-margin"></div>
          </div>
        </div>
      </section>

      <section id="job-orianted-section">
        <div
          class="container
            job-orianted-section-container"
        >
          <div class="row heading-row">
            <div
              class="col
                    job-orianted-section-column"
            >
              <h2 class="job-oriented-heading">
                100% Job Oriented
                <span class="job-oriented-subheading">TRAINING PROGRAMS</span>
              </h2>
              <p class="job-oriented-discription-para">
                We are focused on providing advanced level software training
                like Big Data Training, Hadoop Training, Data Science Training,
                Python Training, Machine Learning Training, Selenium Training in
                Cochin Kerala.
              </p>
            </div>
            <div
              class="col
                    job-orianted-section-column large-device"
            >
              <div class="card-wrapper data-science">
                <div class="card job-oriented-card">
                  <div class="job-oriented-card-body">
                    <div class="circle">
                      <img
                        src={courseOne.icon}
                        alt=""
                        class="img-fluid
                                        circle-icon data-science"
                        height="50px"
                        width="50px"
                      />
                      <img
                        src={courseOne.icon}
                        alt="circle-icon
                                        data-science hover"
                        class="img-fluid circle-icon
                                        data-science hover"
                        height="50px"
                        width="50px"
                      />
                    </div>
                    <h5
                      class="job-oriented-card-title
                                    data-science"
                    >
                      {courseOne.title}
                    </h5>
                    <p class="job-oriented-card-text d-flex ">
                      <span class="job-oriented-card-text-bold">
                        Next Batch:
                      </span>
                      &nbsp; &nbsp; &nbsp;
                      <div className="nextBatch">
                        <p className="now-cochine">
                          <span className="home-sub-date">Cochin</span>{" "}
                          {courseOne.nextBatch}
                        </p>
                        <p className="now-cochine">
                          {" "}
                          <span className="home-sub-date">Calicut</span>
                          &nbsp;
                          {courseOne.nextBatchCalicut}
                        </p>
                        <p className="now-cochine">
                          <span className="home-sub-date">Trivandrum</span>
                          &nbsp;
                          {courseOne.nextBatchTvm}
                        </p>
                      </div>
                    </p>

                    <p class="job-oriented-card-text">
                      <span class="job-oriented-card-text-bold">Duration:</span>{" "}
                      {courseOne.duration}
                    </p>

                    <Link to={`/course-detail/${courseOne.url}`}>
                      <a href="#" class="job-oriented-card-link">
                        View More
                      </a>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col job-orianted-section-column large-device">
              <button
                class="view-all-button-white"
                onClick={() => {
                  navigate("/courses");
                }}
              >
                View All
              </button>
            </div>
            {course &&
              course.map((customer, i) => (
                <div key={i}>
                  {customer.flag === true ? (
                    <div class="col job-orianted-section-column large-device">
                      <div class="card-wrapper python-django">
                        <div class="card job-oriented-card">
                          <div class="job-oriented-card-body">
                            <div class="circle">
                              <img
                                src={customer.icon}
                                alt=""
                                class="img-fluid
                                        python-django
                                        circle-icon"
                                height="50px"
                                width="50px"
                              />

                              <img
                                src={customer.icon}
                                alt="circle-icon
                                        python-django hover"
                                class="img-fluid circle-icon
                                        python-django hover"
                                height="50px"
                                width="50px"
                              />
                            </div>
                            <h5
                              class="job-oriented-card-title
                                    python-django"
                            >
                              {customer.title}
                            </h5>
                            <p class="job-oriented-card-text d-flex ">
                              <span class="job-oriented-card-text-bold">
                                Next Batch:
                              </span>
                              &nbsp; &nbsp; &nbsp;
                              <div className="nextBatch">
                                <p className="now-cochine">
                                  <span className="home-sub-date">Cochin</span>{" "}
                                  {courseOne.nextBatch}
                                </p>
                                <p className="now-cochine">
                                  {" "}
                                  <span className="home-sub-date">Calicut</span>
                                  &nbsp;
                                  {courseOne.nextBatch}
                                </p>
                                <p className="now-cochine">
                                  <span className="home-sub-date">
                                    Trivandrum
                                  </span>
                                  &nbsp;
                                  {customer.nextBatchTvm}
                                </p>
                              </div>
                            </p>

                            <p class="job-oriented-card-text">
                              <span class="job-oriented-card-text-bold">
                                Duration:
                              </span>{" "}
                              {courseOne.duration}
                            </p>
                            <Link to={`/course-detail/${customer.url}`}>
                              <a href="#" class="job-oriented-card-link">
                                View More
                              </a>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}
                </div>
              ))}

            <div class="col-12">
              {/* <Slider {...settings}> */}

              <div class="job-oriented-card-container-small-device">
                {course &&
                  course
                    .filter((c) => c.flag == true)
                    .map((customer, j) => (
                      <div
                        class="card-wrapper python-django"
                        key={customer._id}
                      >
                        <div class="card job-oriented-card">
                          <div class="job-oriented-card-body">
                            <div class="circle">
                              <img
                                src={customer.icon}
                                alt=""
                                class="img-fluid
                                            circle-icon"
                                height="34px"
                                width="34px"
                              />
                            </div>
                            <h5
                              class="job-oriented-card-title
                                        data-science"
                            >
                              {customer.title}
                            </h5>
                            <p class="job-oriented-card-text d-flex ">
                              <span class="job-oriented-card-text-bold">
                                Next Batch:
                              </span>
                              &nbsp; &nbsp; &nbsp;
                              <div className="nextBatch">
                                <p className="now-cochine">
                                  <span className="home-sub-date">Cochin</span>{" "}
                                  {courseOne.nextBatch}
                                </p>
                                <p className="now-cochine">
                                  {" "}
                                  <span className="home-sub-date">Calicut</span>
                                  &nbsp;
                                  {courseOne.nextBatchCalicut}
                                </p>
                                <p className="now-cochine">
                                  <span className="home-sub-date">
                                    Trivandrum
                                  </span>
                                  &nbsp;
                                  {courseOne.nextBatchTvm}
                                </p>
                              </div>
                            </p>

                            <p class="job-oriented-card-text">
                              <span class="job-oriented-card-text-bold">
                                Duration:
                              </span>{" "}
                              {courseOne.duration}
                            </p>

                            <Link
                              to={`/course-detail/${customer.url}`}
                              class="job-oriented-card-link"
                            >
                              View More
                            </Link>
                          </div>
                        </div>
                      </div>
                    ))}
              </div>
              {/* </Slider> */}

              <div
                class="job-oriented-card-container-small-device
                        button"
              >
                <button
                  class="view-all-button-white"
                  onClick={() => {
                    navigate("/courses");
                  }}
                >
                  View All
                </button>
              </div>
            </div>
          </div>
          <img src={Group16} alt="icon-1" class="img-fluid icon-1" />
          <img src={Group15} alt="icon-2" class="img-fluid icon-2" />
          <img src={MaskGroup2} alt="icon-3" class="img-fluid icon-3" />
        </div>
      </section>

      {isValidInstaFeed && (
        <section id="home-instagram-feeds">
          <div class="container">
            <span
              class="d-flex align-items-center
                    justify-content-center
                    home-instagram-feed-heading-section"
            >
              <img
                src={insta1}
                alt="instagram-logo"
                class="img-fluid
                        home-instagram-feed-logo"
              />
              <p class="home-instagram-feed-heading">Instagram Feed</p>
            </span>
            <div id="instafeed" className="insta"></div>
            <div class="row home-instagram-image-galary"></div>
          </div>
        </section>
      )}

      <section class="">
        <div class="home-card-logos web-logos">
          <div class="container">
            <div className="row">
              {/* <div className="col-md-3 placement-slick-head">
                  <img src={whitelogo}/>
                   <h1> Placements</h1>
                </div> */}
              <div className="col-md-12 placement-logos">
                <Slider {...settings}>
                  <div class="col logoipusum-image-col">
                    <img src={dns} class="logoipusum-image" alt="logos" />
                  </div>
                  <div class="col logoipusum-image-col">
                    <img src={fingent} class="logoipusum-image" alt="logos" />
                  </div>
                  <div class="col logoipusum-image-col">
                    <img src={tcs} class="logoipusum-image" alt="logos" />
                  </div>
                  <div class="col logoipusum-image-col">
                    <img src={infosys} class="logoipusum-image" alt="logos" />
                  </div>
                  <div class="col logoipusum-image-col">
                    <img src={cognizant} class="logoipusum-image" alt="logos" />
                  </div>
                  <div class="col logoipusum-image-col">
                    <img src={panapps} class="logoipusum-image" alt="logos" />
                  </div>
                  <div class="col logoipusum-image-col">
                    <img src={impelsys} class="logoipusum-image" alt="logos" />
                  </div>
                  <div class="col logoipusum-image-col">
                    <img
                      src={rapidvalue}
                      class="logoipusum-image"
                      alt="logos"
                    />
                  </div>
                  <div class="col logoipusum-image-col">
                    <img src={nucore} class="logoipusum-image" alt="logos" />
                  </div>
                  <div class="col logoipusum-image-col">
                    <img src={naico} class="logoipusum-image" alt="logos" />
                  </div>
                  <div class="col logoipusum-image-col">
                    <img src={jtsi} class="logoipusum-image" alt="logos" />
                  </div>
                  <div class="col logoipusum-image-col">
                    <img src={tryzens} class="logoipusum-image" alt="logos" />
                  </div>
                  <div class="col logoipusum-image-col">
                    <img src={sementor} class="logoipusum-image" alt="logos" />
                  </div>
                  <div class="col logoipusum-image-col">
                    <img src={testvox} class="logoipusum-image" alt="logos" />
                  </div>
                  <div class="col logoipusum-image-col">
                    <img src={grapelime} class="logoipusum-image" alt="logos" />
                  </div>
                  <div class="col logoipusum-image-col">
                    <img
                      src={growthplug}
                      class="logoipusum-image"
                      alt="logos"
                    />
                  </div>
                  <div class="col logoipusum-image-col">
                    <img src={nuvento} class="logoipusum-image" alt="logos" />
                  </div>
                  <div class="col logoipusum-image-col">
                    <img src={vaisesika} class="logoipusum-image" alt="logos" />
                  </div>
                  <div class="col logoipusum-image-col">
                    <img src={lanware} class="logoipusum-image" alt="logos" />
                  </div>
                  <div class="col logoipusum-image-col">
                    <img src={logicplum} class="logoipusum-image" alt="logos" />
                  </div>
                  <div class="col logoipusum-image-col">
                    <img
                      src={techvantage}
                      class="logoipusum-image"
                      alt="logos"
                    />
                  </div>
                  <div class="col logoipusum-image-col">
                    <img src={thomson} class="logoipusum-image" alt="logos" />
                  </div>
                  <div class="col logoipusum-image-col">
                    <img src={hyundai} class="logoipusum-image" alt="logos" />
                  </div>
                  <div class="col logoipusum-image-col">
                    <img src={gizemeon} class="logoipusum-image" alt="logos" />
                  </div>
                  <div class="col logoipusum-image-col">
                    <img src={draup} class="logoipusum-image" alt="logos" />
                  </div>
                  <div class="col logoipusum-image-col">
                    <img src={enfin} class="logoipusum-image" alt="logos" />
                  </div>
                  <div class="col logoipusum-image-col">
                    <img src={zeazonz} class="logoipusum-image" alt="logos" />
                  </div>
                  <div class="col logoipusum-image-col">
                    <img src={logidots} class="logoipusum-image" alt="logos" />
                  </div>
                  <div class="col logoipusum-image-col">
                    <img src={prudent} class="logoipusum-image" alt="logos" />
                  </div>
                  <div class="col logoipusum-image-col">
                    <img src={beinex} class="logoipusum-image" alt="logos" />
                  </div>
                  <div class="col logoipusum-image-col">
                    <img src={intpurple} class="logoipusum-image" alt="logos" />
                  </div>
                  <div class="col logoipusum-image-col">
                    <img src={NewAgeSys} class="logoipusum-image" alt="logos" />
                  </div>
                  <div class="col logoipusum-image-col">
                    <img src={claysys} class="logoipusum-image" alt="logos" />
                  </div>
                </Slider>
              </div>
            </div>

            {/* <div class="col logoipusum-image-col">
                  <img src={Group27} class="logoipusum-image" alt="logos" />
                </div>
                <div class="col logoipusum-image-col">
                  <img src={Group27} class="logoipusum-image" alt="logos" />
                </div> */}
          </div>
        </div>
        <div class="home-card-logos mobile-logos">
          <div class="container">
            <div className="row">
              {/* <div className="col-md-3 placement-slick-head">
                  <img src={whitelogo}/>
                   <h1> Placements</h1>
                </div> */}
              <div className="col-md-12 placement-logos">
                <Slider {...mobilelogoscroll}>
                  <div class="col logoipusum-image-col">
                    <img src={dns} class="logoipusum-image" alt="logos" />
                  </div>
                  <div class="col logoipusum-image-col">
                    <img src={fingent} class="logoipusum-image" alt="logos" />
                  </div>
                  <div class="col logoipusum-image-col">
                    <img src={tcs} class="logoipusum-image" alt="logos" />
                  </div>
                  <div class="col logoipusum-image-col">
                    <img src={infosys} class="logoipusum-image" alt="logos" />
                  </div>
                  <div class="col logoipusum-image-col">
                    <img src={cognizant} class="logoipusum-image" alt="logos" />
                  </div>
                  <div class="col logoipusum-image-col">
                    <img src={panapps} class="logoipusum-image" alt="logos" />
                  </div>
                  <div class="col logoipusum-image-col">
                    <img src={impelsys} class="logoipusum-image" alt="logos" />
                  </div>
                  <div class="col logoipusum-image-col">
                    <img
                      src={rapidvalue}
                      class="logoipusum-image"
                      alt="logos"
                    />
                  </div>
                  <div class="col logoipusum-image-col">
                    <img src={nucore} class="logoipusum-image" alt="logos" />
                  </div>
                  <div class="col logoipusum-image-col">
                    <img src={naico} class="logoipusum-image" alt="logos" />
                  </div>
                  <div class="col logoipusum-image-col">
                    <img src={jtsi} class="logoipusum-image" alt="logos" />
                  </div>
                  <div class="col logoipusum-image-col">
                    <img src={tryzens} class="logoipusum-image" alt="logos" />
                  </div>
                  <div class="col logoipusum-image-col">
                    <img src={sementor} class="logoipusum-image" alt="logos" />
                  </div>
                  <div class="col logoipusum-image-col">
                    <img src={testvox} class="logoipusum-image" alt="logos" />
                  </div>
                  <div class="col logoipusum-image-col">
                    <img src={grapelime} class="logoipusum-image" alt="logos" />
                  </div>
                  <div class="col logoipusum-image-col">
                    <img
                      src={growthplug}
                      class="logoipusum-image"
                      alt="logos"
                    />
                  </div>
                  <div class="col logoipusum-image-col">
                    <img src={nuvento} class="logoipusum-image" alt="logos" />
                  </div>
                  <div class="col logoipusum-image-col">
                    <img src={vaisesika} class="logoipusum-image" alt="logos" />
                  </div>
                  <div class="col logoipusum-image-col">
                    <img src={lanware} class="logoipusum-image" alt="logos" />
                  </div>
                  <div class="col logoipusum-image-col">
                    <img src={logicplum} class="logoipusum-image" alt="logos" />
                  </div>
                  <div class="col logoipusum-image-col">
                    <img
                      src={techvantage}
                      class="logoipusum-image"
                      alt="logos"
                    />
                  </div>
                  <div class="col logoipusum-image-col">
                    <img src={thomson} class="logoipusum-image" alt="logos" />
                  </div>
                  <div class="col logoipusum-image-col">
                    <img src={hyundai} class="logoipusum-image" alt="logos" />
                  </div>
                  <div class="col logoipusum-image-col">
                    <img src={gizemeon} class="logoipusum-image" alt="logos" />
                  </div>
                  <div class="col logoipusum-image-col">
                    <img src={draup} class="logoipusum-image" alt="logos" />
                  </div>
                  <div class="col logoipusum-image-col">
                    <img src={enfin} class="logoipusum-image" alt="logos" />
                  </div>
                  <div class="col logoipusum-image-col">
                    <img src={zeazonz} class="logoipusum-image" alt="logos" />
                  </div>
                  <div class="col logoipusum-image-col">
                    <img src={logidots} class="logoipusum-image" alt="logos" />
                  </div>
                  <div class="col logoipusum-image-col">
                    <img src={prudent} class="logoipusum-image" alt="logos" />
                  </div>
                  <div class="col logoipusum-image-col">
                    <img src={beinex} class="logoipusum-image" alt="logos" />
                  </div>
                  <div class="col logoipusum-image-col">
                    <img src={intpurple} class="logoipusum-image" alt="logos" />
                  </div>
                  <div class="col logoipusum-image-col">
                    <img src={NewAgeSys} class="logoipusum-image" alt="logos" />
                  </div>
                  <div class="col logoipusum-image-col">
                    <img src={claysys} class="logoipusum-image" alt="logos" />
                  </div>
                </Slider>
              </div>
            </div>

            {/* <div class="col logoipusum-image-col">
                  <img src={Group27} class="logoipusum-image" alt="logos" />
                </div>
                <div class="col logoipusum-image-col">
                  <img src={Group27} class="logoipusum-image" alt="logos" />
                </div> */}
          </div>
        </div>

        <div class="card-section-wrapper">
          <div class="container bg-white">
            <div class="row mb-3">
              <div class="image-dot-parent">
                <img
                  src={dottedImg}
                  alt="doted image"
                  class="img-fluid
                                image-dot"
                />
                <div class="col-12">
                  <p class="about-para">
                    <span class="heading-about">Luminar Technolab</span>
                    is an interesting platform that will teach your education in
                    more an interactive way
                  </p>
                </div>
              </div>
              <div
                class="row d-flex
                            justify-content-center
                            align-items-center"
              >
                <div class="col-12 col-md-4">
                  <div
                    class="card about-card
                                    home-luminar-lab-assurance-card"
                  >
                    <div
                      class="card-body
                                        home-luminar-lab-assurance-card
                                        text-center"
                    >
                      <img
                        src={office}
                        alt="office-chair
                                            icon"
                        class="img-fluid
                                            home-card-section-icon
                                            icon-one"
                      />
                      <h6
                        class="card-title
                                            home-luminar-lab-assurance-card"
                      >
                        Placement assurance
                      </h6>
                      <p
                        class="card-text-discription
                                            home-luminar-lab-assurance-card"
                      >
                        Our mission is to provide 100% placements to students
                        those who enrol for our specialised courses. Our
                        Placement assistance starts with Resume preparation,
                        Mock Interviews by real working professionals, Aptitude
                        test training & Interviews.
                      </p>
                    </div>
                  </div>
                </div>

                <div class="col-12 col-md-4">
                  <div
                    class="card about-card
                                    home-luminar-lab-assurance-card"
                  >
                    <div
                      class="card-body
                                        text-center
                                        home-luminar-lab-assurance-card"
                    >
                      <img
                        src={officechair}
                        alt="office-chair
                                            icon"
                        class="img-fluid
                                            home-card-section-icon
                                            icon-two"
                      />
                      <h6
                        class="card-title
                                            home-luminar-lab-assurance-card"
                      >
                        Certification worth
                      </h6>
                      <p
                        class="card-text-discription
                                            home-luminar-lab-assurance-card"
                      >
                        Our mission is to provide 100% placements to students
                        those who enrol for our specialised courses. Our
                        Placement assistance starts with Resume preparation,
                        Mock Interviews by real working professionals, Aptitude
                        test training & Interviews.
                      </p>
                    </div>
                  </div>
                </div>

                <div class="col-12 col-md-4">
                  <div
                    class="card about-card
                                    home-luminar-lab-assurance-card"
                  >
                    <div
                      class="card-body
                                        text-center
                                        home-luminar-lab-assurance-card"
                    >
                      <img
                        src={icon1}
                        alt="icon"
                        class="img-fluid
                                            home-card-section-icon
                                            icon-three"
                      />
                      <h6
                        class="card-title
                                            home-luminar-lab-assurance-card"
                      >
                        Professional team of tutors
                      </h6>
                      <p
                        class="card-text-discription
                                            home-luminar-lab-assurance-card
                                            three"
                      >
                        Our mission is to provide 100% placements to students
                        those who enrol for our specialised courses. Our
                        Placement assistance starts with Resume preparation,
                        Mock Interviews by real working professionals, Aptitude
                        test training & Interviews.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        class="d-flex justify-content-center
            people-say-about-section"
      >
        <div class="container">
          <div class="row">
            <div class="col-sm-12 text-left">
              <p class="home-say-about-luminar">
                Check what people say about <br />
                <span class="home-say-about-luminar-heading">
                  Luminar Technolab{" "}
                </span>
              </p>
            </div>

            <div class="people-say-about-wrapper">
              <div class="people-say-about-row">
                <motion.div
                  class="card people-say-about-card"
                  key={testimonial.image1}
                  initial={{ opacity: 0, x: 0 }}
                  animate={{ opacity: 1, x: 20 }}
                  transition={
                    ({ x: { dealy: 3, duration: 1 } },
                    { opacity: { dealy: 3, duration: 1 } })
                  }
                >
                  {/* <div class="card people-say-about-card"> */}
                  <div class="card-body people-say-about">
                    <span>
                      <i class="people-say fas fa-quote-left"></i>
                    </span>

                    <p class="home-people-say-about-luminar">
                      {testimonial.testimony}
                    </p>
                    <div class="profile-image-circle">
                      <img
                        src={testimonial.image1}
                        class="img-fluid image-circle-profile"
                        alt="profile-image-circle"
                      />
                    </div>
                    <span class="people-say-name">{testimonial.fullName} </span>
                    <br />
                    <div class="people-say-subject-rating-wrapper">
                      <span class="people-say-designation">
                        {testimonial.designation}
                      </span>

                      <span class="people-say-star">
                        <i class="fas fa-star"></i>
                        <i class="fas fa-star"></i>
                        <i class="fas fa-star"></i>
                        <i class="fas fa-star-half-alt"></i>
                        <i id="fa-star-one" class="fas fa-star"></i>
                      </span>
                    </div>
                  </div>
                  {/* </div> */}
                </motion.div>
                {/* <div className="people-say-card-image-div">
                  <img
                    src={testimonial?.image1}
                    alt="tigo-image"
                    class="people-say-card-image"
                  />
                  </div> */}

                <motion.div
                  class="people-say-card-image-div"
                  key={testimonial.id}
                  initial={{ opacity: 0, x: 0 }}
                  animate={{ opacity: 1, x: 40 }}
                  transition={
                    ({ x: { dealy: 3, duration: 1 } },
                    { opacity: { dealy: 3, duration: 1 } })
                  }
                >
                  <img
                    src={testimonial?.image1}
                    alt="tigo-image"
                    class="people-say-card-image"
                  />
                </motion.div>

                {/* <div className="people-say-card-secondary-image-div">
                  <img
                    src={testimonial?.image2}
                    alt="card-image-2"
                    class="people-say-card-secondary-image"
                  />
                  </div> */}
                <motion.div
                  class="people-say-card-secondary-image-div"
                  key={testimonial.id}
                  initial={{ opacity: 0, x: 0 }}
                  animate={{ opacity: 1, x: 40 }}
                  transition={
                    ({ x: { dealy: 3, duration: 1 } },
                    { opacity: { dealy: 3, duration: 1 } })
                  }
                >
                  <img
                    src={testimonial?.image2}
                    alt="card-image-2"
                    class="people-say-card-image"
                  />
                </motion.div>

                <span class="people-say">
                  <i
                    className={
                      !testimonial.id > 0
                        ? "fas fa-chevron-circle-left"
                        : "fas fa-chevron-circle-left color-blue"
                    }
                    onClick={() => {
                      // if(testimonial.id===0){
                      //   return
                      // }else{
                      // previous(testimonial.id-1)
                      if (currentIndex > 0) {
                        globalCurrentIndex = currentIndex - 1;
                        loadPageWithIndex(globalCurrentIndex);
                      }
                      //}
                    }}
                  ></i>
                  <i class="fas fa-chevron-circle-right"></i>
                </span>

                <span
                  class="people-say"
                  onClick={() => {
                    // if(testimonial.id>=test.length-1){

                    //   return
                    // }else{
                    //next(testimonial.id+1)
                    if (currentIndex < test.length) {
                      globalCurrentIndex = currentIndex + 1;
                      loadPageWithIndex(globalCurrentIndex);
                    }
                    //}
                  }}
                >
                  <i
                    className={
                      testimonial.id < test.length - 1
                        ? "fas fa-chevron-circle-right"
                        : "fas fa-chevron-circle-right color-white"
                    }
                    // class=""
                  ></i>
                </span>
                {/* <div className="people-say-card-secondary-image-div">
                  <img
                    src={testimonial?.image2}
                    alt="card-image-2"
                    class="people-say-card-secondary-image"
                  />
                  </div> */}
              </div>
              {/* })}  */}
            </div>
          </div>
        </div>
      </section>

      <section id="course-vedio-section">
        <div class="container">
          {home &&
            home.map((data, i) => {
              return (
                <div class="row d-flex">
                  <div class=" col-sm-12 col-lg-6  p-0">
                    <div div class="courses-python-parent">
                      {/* <iframe width="600"  className="video" height="400" 
                      src={stringData}
                      title="YouTube video player" 
                      rel="0"
                      frameborder="0" allow="accelerometer; autoplay; clipboard-read; clipboard-write; encrypted-media; gyroscope; picture-in-picture"  name="iframe_video1" allowfullscreen="allowfullscreen"></iframe> */}
                      <iframe
                        src={stringData}
                        width="100%"
                        height="100%"
                        allowfullscreen="allowfullscreen"
                      ></iframe>
                    </div>
                  </div>
                  <div
                    class="col-sm-12 col-lg-6
                        courses-content-right text-left"
                  >
                    <br />
                    <img
                      src={videoPlay}
                      alt="python image"
                      class="img-fluid
                            python-vedio-play-image"
                    />

                    <div className="course-video-section">
                      {ReactHtmlParser(home[0].videoDescription)}
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </section>
      <section id="home-technology-stack">
        <div className="container">
          <div class="row">
            <div class="col-12 col-md-6 home-carousel-two-image-section">
              <div class="home-carousel-two-image-section-one">
                <img
                  src={carousal1Img1}
                  alt="carousel-two-computer-for-student-image"
                  class="home-carousel-two-image-one"
                />
              </div>

              <div class="home-carousel-two-image-section-two">
                <img
                  src={carousal1Img2}
                  alt="carousel-two-computer-for-student-image"
                  class="home-carousel-two-image-two"
                />
              </div>
              <div class="home-carousel-two-white-box"></div>
            </div>
            <div class="col-12 col-md-6 home-carousel-two-text-section">
              <h1 class="home-carousel-two-body-heading">Technology Stack</h1>
              <p class="home-carousel-two-body-detials">
                Our mission is to provide 100% placements to students those who
                enrol for our specialised courses.
              </p>

              <div
                id="technologystack"
                className="carousel slide"
                data-ride="carousel"
                data-interval="2000"
              >
                <ol className="home-technology-stack-carousel-indicators-two carousel-indicators">
                  <li
                    data-target="#technologystack"
                    data-slide-to={0}
                    className="active"
                  />
                  <li data-target="#technologystack" data-slide-to={1} />
                  <li data-target="#technologystack" data-slide-to={2} />
                </ol>
                <div className="carousel-inner">
                  <div className="carousel-item active">
                    <div class="home-carousel-two-body-stacks">
                      <div class="home-carousel-two-body-technology-stacks">
                        <img
                          src={docker1}
                          alt="stack-image-one"
                          class="home-carousel-two-body-technology-stacks-stack-image
                                                                        docker-image"
                        />
                      </div>
                      <div class="home-carousel-two-body-technology-stacks">
                        <img
                          src={python5}
                          alt="stack-image-two"
                          class="home-carousel-two-body-technology-stacks-stack-image
                                                                        python-image"
                        />
                      </div>
                      <div class="home-carousel-two-body-technology-stacks">
                        <img
                          src={devops2}
                          alt="stack-image-three"
                          class="home-carousel-two-body-technology-stacks-stack-image
                                                                        devops-image"
                        />
                      </div>
                      <div
                        class="home-carousel-two-body-technology-stacks
                                                                    row-one-last"
                      >
                        <img
                          src={elastic}
                          alt="stack-image-one"
                          class="home-carousel-two-body-technology-stacks-stack-image
                                                                        docker-image"
                        />
                      </div>
                    </div>
                    <div class="home-carousel-two-body-stacks">
                      <div class="home-carousel-two-body-technology-stacks">
                        <img
                          src={jquery}
                          alt="stack-image-four"
                          class="home-carousel-two-body-technology-stacks-stack-image
                                                                        jquery-image"
                        />
                      </div>
                      <div class="home-carousel-two-body-technology-stacks">
                        <img
                          src={bootstrap}
                          alt="stack-image-five"
                          class="home-carousel-two-body-technology-stacks-stack-image
                                                                        bootstrap-image"
                        />
                      </div>
                      <div class="home-carousel-two-body-technology-stacks">
                        <img
                          src={kb}
                          alt="stack-image-six"
                          class="home-carousel-two-body-technology-stacks-stack-image
                                                                        kubernets-image"
                        />
                      </div>
                      <div
                        class="home-carousel-two-body-technology-stacks
                                                                    row-two-last"
                      >
                        <img
                          src={jenkins}
                          alt="stack-image-four"
                          class="home-carousel-two-body-technology-stacks-stack-image
                                                                        python-image"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="carousel-item">
                    <div class="home-carousel-two-body-stacks">
                      <div class="home-carousel-two-body-technology-stacks">
                        <img
                          src={angular}
                          alt="stack-image-one"
                          class="home-carousel-two-body-technology-stacks-stack-image
                                                                        docker-image"
                        />
                      </div>
                      <div class="home-carousel-two-body-technology-stacks">
                        <img
                          src={react}
                          alt="stack-image-two"
                          class="home-carousel-two-body-technology-stacks-stack-image
                                                                        python-image"
                        />
                      </div>
                      <div class="home-carousel-two-body-technology-stacks">
                        <img
                          src={vuejs}
                          alt="stack-image-three"
                          class="home-carousel-two-body-technology-stacks-stack-image
                                                                        devops-image"
                        />
                      </div>
                      <div
                        class="home-carousel-two-body-technology-stacks
                                                                    row-one-last"
                      >
                        <img
                          src={github}
                          alt="stack-image-one"
                          class="home-carousel-two-body-technology-stacks-stack-image
                                                                        github-image"
                        />
                      </div>
                    </div>
                    <div class="home-carousel-two-body-stacks">
                      <div class="home-carousel-two-body-technology-stacks">
                        <img
                          src={magneto}
                          alt="stack-image-four"
                          class="home-carousel-two-body-technology-stacks-stack-image
                                                                        jquery-image"
                        />
                      </div>
                      <div class="home-carousel-two-body-technology-stacks">
                        <img
                          src={android}
                          alt="stack-image-five"
                          class="home-carousel-two-body-technology-stacks-stack-image
                                                                        jquery-image"
                        />
                      </div>
                      <div class="home-carousel-two-body-technology-stacks">
                        <img
                          src={amazon}
                          alt="stack-image-six"
                          class="home-carousel-two-body-technology-stacks-stack-image
                                                                        kubernets-image"
                        />
                      </div>
                      <div
                        class="home-carousel-two-body-technology-stacks
                                                                    row-two-last"
                      >
                        <img
                          src={sql}
                          alt="stack-image-four"
                          class="home-carousel-two-body-technology-stacks-stack-image
                                                                        jquery-image"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="carousel-item">
                    <div class="home-carousel-two-body-stacks">
                      <div class="home-carousel-two-body-technology-stacks">
                        <img
                          src={mongodb}
                          alt="stack-image-one"
                          class="home-carousel-two-body-technology-stacks-stack-image
                                                                        docker-image"
                        />
                      </div>
                      <div class="home-carousel-two-body-technology-stacks">
                        <img
                          src={mysql}
                          alt="stack-image-two"
                          class="home-carousel-two-body-technology-stacks-stack-image
                                                                        python-image"
                        />
                      </div>
                      <div class="home-carousel-two-body-technology-stacks">
                        <img
                          src={ios}
                          alt="stack-image-three"
                          class="home-carousel-two-body-technology-stacks-stack-image
                                                                        devops-image"
                        />
                      </div>
                      <div
                        class="home-carousel-two-body-technology-stacks
                                                                    row-one-last"
                      >
                        <img
                          src={docker1}
                          alt="stack-image-one"
                          class="home-carousel-two-body-technology-stacks-stack-image
                                                                        docker-image"
                        />
                      </div>
                    </div>
                    <div class="home-carousel-two-body-stacks">
                      <div class="home-carousel-two-body-technology-stacks">
                        <img
                          src={dotnet}
                          alt="stack-image-four"
                          class="home-carousel-two-body-technology-stacks-stack-image
                                                                        jquery-image"
                        />
                      </div>
                      <div class="home-carousel-two-body-technology-stacks">
                        <img
                          src={node}
                          alt="stack-image-five"
                          class="home-carousel-two-body-technology-stacks-stack-image
                                                                        bootstrap-image"
                        />
                      </div>
                      <div class="home-carousel-two-body-technology-stacks">
                        <img
                          src={java}
                          alt="stack-image-six"
                          class="home-carousel-two-body-technology-stacks-stack-image
                                                                        kubernets-image"
                        />
                      </div>
                      <div
                        class="home-carousel-two-body-technology-stacks
                                                                    row-two-last"
                      >
                        <img
                          src={kb}
                          alt="stack-image-four"
                          class="home-carousel-two-body-technology-stacks-stack-image
                                                                        jquery-image"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default Home;
