// const API = 'https://luminartechnolab-clnt-backend.herokuapp.com/api';
const API = process.env.REACT_APP_API_URL;

 //const API ='http://localhost:9000/api'

const axios = require('axios');
const headers = {
  'Access-Control-Allow-Origin': '*',
  'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS'
};


export const createEnquiry = (data) =>{
    return axios.post(`${API}/enquiry/create`,
        data,{headers}).then((data)=>{
        return data.data
    }).catch((err)=>{
        return err.response.data
    })
  }

  export const createEnquiryForms = (data) =>{
    console.log("data",data)
    return axios.post(`${API}/EnquiryForm/create`,
        data,{headers}).then((data)=>{
        return data.data
    }).catch((err)=>{
        return err.response.data
    })
  }

  export const sentMail = (email,name,phoneNumber,course,city,Message) =>{
    let data ={}
    data.email=email
    data.name=name
    data.phoneNumber=phoneNumber
    data.course=course
    data.city=city
    data.Message=Message
    return axios.post(`${API}/enquiry/sentMail`,data,
        {headers}).then((data)=>{
        return data.data
    }).catch((err)=>{
        console.log(err,"errir");
        return err
    })
  }

  export const sentMailEnquiryForms = (email,name,phoneNumber,course,city,Message) =>{
    let data ={}
    data.email=email
    data.name=name
    data.phoneNumber=phoneNumber
    data.course=course
    return axios.post(`${API}/EnquiryForm/sentMail`,data,
        {headers}).then((data)=>{
        return data.data
    }).catch((err)=>{
        console.log(err,"errir");
        return err
    })
  }