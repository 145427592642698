// const API = 'https://luminartechnolab-clnt-backend.herokuapp.com/api';
 //const API ='http://localhost:9000/api'
const API = process.env.REACT_APP_API_URL;

const axios = require('axios');
const headers = {
  'Access-Control-Allow-Origin': '*',
  'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS'
};


export const payment = (data) =>{
    return axios.post(`${API}/payment/order`,
        data,{headers}).then((data)=>{
        return data.data
    }).catch((err)=>{
        return err.response.data
    })
  }

  export const sentMail = (email,orderId,title) =>{
    return axios.post(`${API}/payment/sentMail/${email}/${orderId}/${title}`,
        {headers}).then((data)=>{
        return data.data
    }).catch((err)=>{
        return err.response.data
    })
  }